// import * as React from "react";
import React, { useEffect } from 'react';
// import { graphql, Link } from "gatsby"
// import "../../css/talent.css";
import ninaBody from "../../images/nina/nina.png";
import ninaBody2 from "../../images/nina/Nina2.png";
import ninaNum from "../../images/nina/01.png";
import ninaLogo from "../../images/nina/NinaName.png";
import TalentCore from '../../component/talentCore';

const nina_details = {
   name: `nina`,
   description: `自由と歌をこよなく愛する少女。   
    ひょんなことから《PROJECT NEBULA》に巻き込まれ、プロジェクトチームの一員として『この世界』に来ることになった。   
    持ち前の明るさでみんなを引っ張るリーダータイプ。`,
   slogan_top: `「自由に歌えるだけで、私は幸せだから…。」`,
   slogan_bot: `「みんな、になについてきてっ！」`,
   twitter: "https://twitter.com/momose_nina",
   youtube: "https://www.youtube.com/channel/UCGCNass-jSHshAHA4ufX3Ng?sub_confirmation=1",
   hashtag: "#桃瀬にな",
   illustrator: "とき",
   illustrator_link: "https://twitter.com/toki_ship8",
   modeler: "グリルドしゃぶしゃぶ",
   modeler_link: "https://twitter.com/grilled_shabux2",
   number: ninaNum,
   logo: ninaLogo,
   keyIllust: ninaBody2,
   baseIllust: ninaBody
}

const Momose_Nina = () => {
   return (
      <TalentCore details={nina_details}/>
   )
}

export default Momose_Nina